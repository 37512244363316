import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import PostList from "../components/post/PostList"
import Title from "../components/layout/Title"
import styled from "styled-components"

const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 40px;
  @media (min-width: 768px) {
    width: 1000px;
    margin: 0 auto;
  }
`

function Category({ data, pageContext }) {
  const category = pageContext.category
  return (
    <Layout>
      <Container>
        <Title title={category} />
        <PostList posts={data} />
      </Container>
    </Layout>
  )
}

export default Category

export const query = graphql`
  query($category: String!) {
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: $category } } }
      sort: { order: DESC, fields: frontmatter___created_at }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            created_at
            updated_at
            categories
            topImage {
              relativePath
              childImageSharp {
                id
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
